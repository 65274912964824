/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var APP = {
	Modules: {},
	Scripts: {},
};

;(function (App, $) {

	var views = [];
	var router = null;

	// custom filter to prevent initializing modules twice
	$.expr[':'].initialized = function (elem, index, match) {
		return $(elem).attr('initialized');
	};


	/**
	 * init data-js modules
	 */
	function initModules() {
		// run all data-js modules
		$('[data-js]').not(':initialized').each(function () {
			var $this = $(this);
			var moduleName = $this.data('js');

			if (App && App.Modules && App.Modules[moduleName] && typeof App.Modules[moduleName] === 'function') {
				var options = $this.data('options');
				if (typeof options == 'string') {
					options = JSON.parse(options);
				}
				var view = new App.Modules[moduleName]({el: $this, model: new Backbone.Model(options)});
				views.push(view);

				// add initialized attribute to prevent initializing multiple times
				$this.attr('initialized', 'true');
			}
		});
	}


	/***
	 * router setup / handle routes
	 */
	function setupRouter() {
		// Initiate the router
		router = new APP.Router;

		router.on('route:defaultRoute', function(actions) {
		});
	}

	// All pages
	App.Scripts.common = {
		// JavaScript to be fired on all pages before page scripts execute
		init: function () {

			setupRouter();

			// Start Backbone history a necessary step for bookmarkable URL's
			Backbone.history.start();
			// set vex default style
			vex.defaultOptions.className = 'vex-theme-wireframe';

			// mobile menu click
			$('.hamburger').click(function (e) {
				e.preventDefault();
				$(this).toggleClass('is-active ');
				$('body').toggleClass('nav-visible');
			});

			// handle main menu clicks
			if (!is_front_page) {
				$('#nav .menu-item a[href^="#"]').each(function () {
					$(this).attr('href', '/' + $(this).attr('href'));
				});
			} else {
				$('#nav .menu-item a[href^="#"]').on('click', function (e) {
					var href = $(this).attr('href');
					$('#nav .menu-item').removeClass('current-menu-item current_page_item');
					$('#nav .menu-item a[href="' + href + '"]').parent('li').addClass('current-menu-item current_page_item');
					$('body').removeClass('nav-visible');
					$('.hamburger').removeClass('is-active');
				});
			}

			// handle hashes
			if (window.location.hash && $(window.location.hash).length !== 0) {
				$('#nav .menu-item').removeClass('current-menu-item current_page_item');
				$('#nav .menu-item a[href="' + window.location.hash + '"]').parent('li').addClass('current-menu-item current_page_item');
			} else {
				// Fragment doesn't exist
			}

			// gravity forms spinner
			if (typeof gform == 'object') {
				gform.addFilter('gform_spinner_target_elem', function ($targetElem, formId) {
					return $('#gform_submit_button_' + formId + '__label');
				});
			}

			// enable click on logo to go home
			if (!is_front_page) {
				$('#title-area').on('click', function (e) {
					location.href = '/';
				});
			}

			// // save server-side generated token
			// var token = null;
			// try {
			// 	token = localStorage.getItem('token');
			// 	if (!token && App.Config.token) {
			// 		token = App.Config.token;
			// 		localStorage.setItem('token', token);
			// 	}
			// }
			// catch(err){}
			//
			// // determine browser fingerprint
			// var fp = null;
			// try {
			// 	fp = localStorage.getItem('fp');
			// 	if (!localStorage.getItem('fp')) {
			// 		new Fingerprint2().get(function (result, components) {
			// 			fp = result;
			// 			localStorage.setItem('fp', result);
			// 		});
			// 	}
			// }
			// catch(err){}
			//
			// $.ajaxSetup({
			// 	headers: {'X-BROWSER-FP': fp, 'X-VOTE-TOKEN': token}
			// });
			//
			// // add YouTube API script
			// var tag = document.createElement('script');
			// tag.id = 'yt-api';
			// tag.src = 'https://www.youtube.com/iframe_api';
			// var firstScriptTag = document.getElementsByTagName('script')[0];
			// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


			$(document).ajaxComplete(function (event, xhr, settings) {
				initModules();
			});
			initModules();
		},

		// JavaScript to be fired on all pages after page scripts executed
		finalize: function () {
		},

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = App.Scripts;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				// UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(APP, jQuery);
