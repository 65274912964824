(function ($) {

	$.fn.tabs = function (options) {

		return this.each(function () {
			var $elem = $(this);

			$('.tabs__menu-link', $elem).on('click', function (e) {
				e.preventDefault();
				show_content($(this).parent('li').index());
				return false;
			});

			show_content(0);

			function show_content(index) {
				// Make the content visible
				$('.tabs__content', $elem).removeClass('is-visible');
				$('.tabs__content-container > .tabs__content:eq(' + index + ')', $elem).addClass('is-visible');

				// Set the tab to selected
				$('.tabs__menu-item', $elem).removeClass('is-selected');
				$('.tabs__menu > .tabs__menu-item:eq(' + index + ')', $elem).addClass('is-selected');
			}


		});
	};

	$('.tabs').tabs();

}(jQuery));
