(function(){
	var Router = Backbone.Router.extend({
		routes: {
			"*actions": "defaultRoute"
		},

		defaultRoute: function (actions) {
		}
	});

	APP.Router = Router;
}(APP, jQuery));

