;(function (App, $) {

	App.Modules['helpdesk-login'] = Backbone.View.extend({
		events: {
			'click': 'onClick'
		},

		initialize: function (options) {

			var bindEvents = false;

			if (1 == 1) {
				bindEvents = true;
			}

			this.$modal = $('#isl-connect-modal');
			this.$form = $('#isl-connect-form');
			this.$feedback = $('#isl-feedback');
			this.$code = $('#isl-code-field');
			this.$close = $('#isl-connect-modal__close');

			if (bindEvents) {
				// // This function disables the use of letters in field
				this.$code.keydown(function (e) {
					var theEvent = e || window.event;
					var key = theEvent.keyCode || theEvent.which;
					if (key == 96 || key == 97 || key == 98 || key == 99 || key == 100 || key == 101 || key == 102 || key == 103 || key == 104 || key == 105 || key == 48 || key == 49 || key == 50 || key == 51 || key == 52 || key == 53 || key == 54 || key == 55 || key == 56 || key == 57 || key == 8) {
						return true;
					}
					e.preventDefault();
					return false;
				});

				// // This function enters the code
				// // To change language change the "&lang=XX"
				this.$form.submit(function (e) {
					return this.isl_connect();
				// 	var code = encodeURIComponent(this.$code.val());
				// 	alert(code);
				// 	var url = 'https://www.ntradmin.com/main2/ntradmin.web.services/remotecontrol/downloadclientexe/?code=' + code + "&lang=EN";
				// 	window.open(url, 'support_window');
				// 	e.preventDefault();
				// 	return false;
				}.bind(this));

				this.$close.click(function () {
					this.$code.val('').blur();
					this.$modal.hide();
				}.bind(this));
			}

		},

		onClick: function () {
			this.$modal.show();
			this.$code.focus();
		},


		isl_connect: function () {
			var self = this;

			var is_msie = navigator.userAgent.indexOf('MSIE') >= 0;

			self.$feedback.html('Bezig verbinding te maken...');
			self.$feedback.removeClass('isl-error isl-success').addClass('isl-busy');

			ISLOnline.Join.getSessionInfoByCode(
				self.$code.val(),
				function (info) {
					self.$feedback.html('Verbinding maken met sessie ' + info.getAttribute('sessionCode'));
					self.$feedback.addClass('isl-success');

					if (is_msie) {
						var $msieMsg = $('<p>Klik op onderstaande knop</p>');
						var $msieBtn = $('<button name="join" value="Start"></button>');
						$msieBtn.on('click', function(){
							info.join();
						});
						self.$feedback.append($msieMsg);
						self.$feedback.append($msieBtn);
					} else {
						info.join();
					}
				},
				function (error) {
					self.$feedback.removeClass('isl-busy');
					self.$feedback.addClass('isl-error');
					self.$feedback.html('Verbinden mislukt; ongeldige code');
					self.$code.focus();
				}
			);
			return false;
		},

	});


}(APP, jQuery));

